import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SelectCards from 'components/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/SelectCards';
import NavButtons from 'components/Outdoor_Cameras/IN-9008_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 Full HD Quick Installation",
  "path": "/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "IN-9008 Full HD Quick Installation",
  "image": "../../P_SearchThumb_IN-9008HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9008HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-9008 Full HD Quick Installation' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9008 Full HD Quick Installation' image='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-9008HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9008_HD/Schnell_Installation/PoE_or_WiFi/' locationFR='/fr/Outdoor_Cameras/IN-9008_HD/Quick_Installation/PoE_or_WiFi/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <Link to="/Outdoor_Cameras/IN-9008_HD/Quick_Installation/" mdxType="Link"><EuiButton color="danger" fill mdxType="EuiButton">Return to Section I</EuiButton></Link>
    <h2 {...{
      "id": "ii-a-power-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-a-power-cable",
        "aria-label": "ii a power cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-a Power Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/99072/IN-9008-Quick-Installation-II-A.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNElEQVQoz52S227DMAhA8//fuEmbtqZKWicOYCDgy9R0y6pu0i7nEXEMGLpa6zzPIQRVbX+kq7UiIgCs6/ofed1w9z/LrbWcs7vXWr/N+BrPOcNGd5dXSrnNvvZ1J7t73PiUzUxVzex2hFKKmd11kXNm5lprVz5IzL6hG3sjt5Wv8h7s+r6XlLIoL8CIKSUzC9Pk7kR0Op1ijCmlGOO6rsfjcRgG27jI0zQhoqWE0zSOY4yREE/DAADLsvR9f+z7l+fnEAIAjON4OBz2obrz+Xx5lUiWhRGRiCkJoIhc+8+6FhHfSokIM3+uyt0vP1yqiqhINtMFLPF1RAAIIdA5tFy+3/NOqZWQcJ7bzcFk0aeHRwX8QW6tqSoCKCXP+X2FqsPLq/xGZmYiQrzMvN8TITKnr/IbG9s1zRTdiGQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/e4706/IN-9008-Quick-Installation-II-A.avif 230w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/d1af7/IN-9008-Quick-Installation-II-A.avif 460w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/899b7/IN-9008-Quick-Installation-II-A.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/a0b58/IN-9008-Quick-Installation-II-A.webp 230w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/bc10c/IN-9008-Quick-Installation-II-A.webp 460w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/6366e/IN-9008-Quick-Installation-II-A.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/81c8e/IN-9008-Quick-Installation-II-A.png 230w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/08a84/IN-9008-Quick-Installation-II-A.png 460w", "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/99072/IN-9008-Quick-Installation-II-A.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/316bbaf56bf3c8410c170efeb7e0f4a9/99072/IN-9008-Quick-Installation-II-A.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Thread the 3m power cable through the wall mount, screw cap, 1-holed sealing ring and the back plate of your camera.`}</p>
    <h2 {...{
      "id": "ii-b-sealing-ring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-b-sealing-ring",
        "aria-label": "ii b sealing ring permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-b Sealing Ring`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/99072/IN-9008-Quick-Installation-II-B.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdklEQVQoz32Ty3KrMBBE+f8/8tJee+GtN6bKMuKhQiAhaTR6cesyDiFJJWdlcHdPz1RRxRillEIIpdQ4jsMw0KMxZt0AAHpJ/44fhBCqUgoiAgAieu8BwGwgIplzzt77EAIJ8IOcc0XZnPPn88k5996Tp2zQj3VdEZE0TdNQqVLKe3LXdafTqW3bUkrOeT3gvaciXdedz+dpmihuXde3WSl1vV4556QLIRwjSDPP8+12G8fRWvs2Uzbn3Dk3z7NS6udwqt227bIsUkql1Lv2MX79k/0KO1WM0RjjnAOAZVmstc65oyjGqLUmDbEftQohaK2VUsaYeZ5Jd4wPIUgpp2lalkVtfNmZSCmFjZTSb7XzxmftUkrcGIahruvH4yGEOHpyziklay1jrK7r1+tFk/8fLKWktUbEGOPlcrnf79RiN9OeiNj3PWPMObcPr/arAEBd14yxvu9jjN8KAwAtLITQWn8xl1Loe6CGPxdGxKZp6JwAQOZ/X9oqfPCyn6IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd18d39374233b00b4f7a0fe8af51dd8/e4706/IN-9008-Quick-Installation-II-B.avif 230w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/d1af7/IN-9008-Quick-Installation-II-B.avif 460w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/899b7/IN-9008-Quick-Installation-II-B.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd18d39374233b00b4f7a0fe8af51dd8/a0b58/IN-9008-Quick-Installation-II-B.webp 230w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/bc10c/IN-9008-Quick-Installation-II-B.webp 460w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/6366e/IN-9008-Quick-Installation-II-B.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd18d39374233b00b4f7a0fe8af51dd8/81c8e/IN-9008-Quick-Installation-II-B.png 230w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/08a84/IN-9008-Quick-Installation-II-B.png 460w", "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/99072/IN-9008-Quick-Installation-II-B.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd18d39374233b00b4f7a0fe8af51dd8/99072/IN-9008-Quick-Installation-II-B.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Loosen plastic mount for the sealing ring in the middle of the back plate. Press the sealing ring into its mount and fasten it with the screw cap.`}</p>
    <h2 {...{
      "id": "ii-c-power--antenna-cable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-c-power--antenna-cable",
        "aria-label": "ii c power  antenna cable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II-c Power & Antenna Cable`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbc3da51af0120cbac1eec8c6adcb811/99072/IN-9008-Quick-Installation-II-C.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABLklEQVQoz6WR227jMAxE9f9/mJcAeUpkSUFjmTdRl4XM1nULLLDtnkd7hpyhXGtNRMoJEWmtjTFaa6WUumMa2TFZ792JyOPxeD6fMcYQQorxfr8z8xiDiC6Xy/V6vd1uy7KklLz3IYSp9ItWdeNM76Po+YOIICIRfZfplDmLZ9mqahfp46/0Pn/2veo0t9aYuZSiqkKkiGeple8nWu+jNQs4OxORqs6rMNOaD7OqAsC6rgBAHyAibhu8vbXeHRExM+4Icwqh1moLZxYRAGBm2XNaii3nxXsRmeZ1XVNKtiTGuG2bSe1tbErOXxLlnN/NNt48RAQAdphSChG9Xi9EPB7c4njvicipKiLyDgCUUo4NtVabZdWOzrVWu7GzGjbPFv477jjDT52f5t/xX+Y/egY2SLrGlaEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbc3da51af0120cbac1eec8c6adcb811/e4706/IN-9008-Quick-Installation-II-C.avif 230w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/d1af7/IN-9008-Quick-Installation-II-C.avif 460w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/899b7/IN-9008-Quick-Installation-II-C.avif 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbc3da51af0120cbac1eec8c6adcb811/a0b58/IN-9008-Quick-Installation-II-C.webp 230w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/bc10c/IN-9008-Quick-Installation-II-C.webp 460w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/6366e/IN-9008-Quick-Installation-II-C.webp 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbc3da51af0120cbac1eec8c6adcb811/81c8e/IN-9008-Quick-Installation-II-C.png 230w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/08a84/IN-9008-Quick-Installation-II-C.png 460w", "/en/static/cbc3da51af0120cbac1eec8c6adcb811/99072/IN-9008-Quick-Installation-II-C.png 842w"],
              "sizes": "(max-width: 842px) 100vw, 842px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbc3da51af0120cbac1eec8c6adcb811/99072/IN-9008-Quick-Installation-II-C.png",
              "alt": "IN-9008 Full HD Schnell Installation",
              "title": "IN-9008 Full HD Schnell Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Plug in the 3m Power Supply and Antenna Cable (if previously removed).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SelectCards mdxType="SelectCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      